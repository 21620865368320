body {
  background-color: #ffffff;
  margin: 0px;
  padding: 0px;
}

html, body {
  height: 100%;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}

.footer-container {
  margin-top: auto;
}

.footer {
  background-color: #2176FF;
  padding: 30px;
  text-align: center;
}

.footer h2 {
  color: #ffffff;
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  font-weight: 100;
  letter-spacing: 2px;
}

#contact {
  padding: 50px 0;
  text-align: center;
  background-color: #f0f2f5;
}

#contact h2 {
  margin-bottom: 30px;
  font-size: 32px;
  color: #333;
}

.contact-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.contact-icon {
  color: #3a85ff;
  font-size: 40px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-icon:hover {
  color: #5a9bff;
}

a {
  cursor: pointer;
  text-decoration: none;
}

/* Additional CSS from your previous setup */
.navigation {
  box-sizing: border-box;
  background-color: #ffffff;
  border-bottom: 1px solid #dddddd;
  left: 0px;
  overflow-y: auto;
  padding: 25px;
  position: fixed;
  text-align: center;
  top: 0px;
  width: 100%;
  z-index: 999;
}
ul {
  display: flex;
  justify-content: center;
  margin: 0px;
  padding: 0px;
  list-style: none;
}

a.logo {
  color: #2176ff;
  font-family: 'Amatic SC', sans-serif;
  font-size: 34px;
  padding: 0px 60px;
  text-align: center;
  text-transform: uppercase;
}
.banner {
  background-image: url("../images/LagoPehoe.jpg");
  background-position: center top;
  background-size: cover;
  border-bottom: 1px solid #dddddd;
  height: 700px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 1200px;
}

.banner-text {
  max-width: 50%;
  color: white;
}

.banner h1 {
  font-size: 50px;
  margin: 0;
}

.banner h2 {
  font-size: 80px;
  margin: -20px 0 40px 0;
  color: #fff;
}

.banner-image {
  max-width: 50%;
  text-align: right;
}

.banner-image img {
  max-width: 60%;
  height: auto;
  border-radius: 18px;
}

.btn-main,
.btn-secondary {
  background-color: #3a85ff;
  color: #fff;
  padding: 10px 20px;
  margin: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 16px;
}

.btn-main:hover,
.btn-secondary:hover {
  background-color: #5a9bff;
}

.btn-secondary {
  background-color: #3a85ff;
  border: 2px solid #3a85ff;
  color: #fff;
}

.btn-secondary:hover {
  background-color: #3a85ff;
  color: white;
}

@media (max-width: 900px) {
  .banner-content {
    flex-direction: column;
    text-align: center;
  }

  .banner-text {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .banner-image {
    max-width: 100%;
    text-align: center;
  }

  .banner h2 {
    font-size: 60px;
  }
}
h1 {
  color: #ffffff;
  font-family: 'Raleway', sans-serif;
  font-size: 60px;
  font-weight: 100;
  letter-spacing: 2px;
  margin: 0px;
  text-align: center;
  text-transform: uppercase;
}
h2 {
  color: #2176FF;
  font-family: 'Amatic SC', sans-serif;
  font-size: 30px;
  margin: 0px;
  text-align: center;
  text-transform: uppercase;
}

.content {
  background-image: url(../images/mountain1.webp);
  background-position: center top;
  background-size: cover;
  border-bottom: 1px solid #eeeeee;
  margin: 0 auto;
  overflow-y: auto;
  padding: 80px 0px;
  position: relative;
  text-align: center;
}
p {
  color: #000000;
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  line-height: 42px;
  text-align: left;
}
.description {
  display: inline-block;
  width: 70%;
}

.activities-panel {
  margin-bottom: 40px;
  padding: 80px 0;
  text-align: center;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
}

.activities-panel h2 {
  width: 100%;
  margin-bottom: 20px;
}

.filters {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.filter-btn {
  background-color: #e0e0e0;
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
}

.filter-btn.active {
  background-color: #3a85ff;
  color: #ffffff;
}

.activities-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  width: 100%;
}

.activity {
  background-color: #2176FF;
  box-sizing: border-box;
  width: 240px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: 'Amatic SC', sans-serif;
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
  margin: 5px;
  border-radius: 8px;
}

/* Responsive adjustments */
@media (max-width: 900px) {
  .activity {
    width: calc(50% - 20px);
  }
}

@media (max-width: 600px) {
  .activity {
    width: 100%;
  }
}

/* CSS for smaller screens */
@media (max-width: 600px) {
  .navigation {
    padding: 5px;
  }

  .navigation ul {
    flex-wrap: wrap;
    justify-content: center;
  }

  .nav-link {
    padding: 0 5px;
    font-size: 14px;
  }

  .logo {
    padding: 0 20px;
    font-size: 20px;
  }

  .banner {
    height: auto; 
    padding: 80px 20px 20px 20px; 
    flex-direction: column; 
  }

  .banner-image {
    order: -1; 
    text-align: center;
    width: 50%; 
    max-width: 150px; 
    margin-bottom: 20px; 
    margin-top: 50px; 
    margin-left: -30px;
     }

  .banner-image img {
    width: 100%; 
    height: auto; 
    border-radius: 50%; 
  }

  .banner-text {
    text-align: center; 
    max-width: 100%; 
  }

  .banner h1 {
    font-size: 24px; 
    margin-bottom: 10px; 
  }

  .banner h2 {
    font-size: 32px; 
    margin-top: 10px; 
  }

  .btn-main, .btn-secondary {
    padding: 5px 10px;
    font-size: 14px;
  }
}

/* CSS for horizontal scrolling on smaller screens */
@media (max-width: 600px) {
  .filters {
    display: flex;
    overflow-x: auto; 
    -webkit-overflow-scrolling: touch; 
    scroll-snap-type: x mandatory; 
    white-space: nowrap; 
  }

  .filter-btn {
    flex: 0 0 auto; 
    scroll-snap-align: start; 
    margin-right: 10px; 
  }
}