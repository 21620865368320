.filters {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .filter-btn {
    background-color: #e0e0e0;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    text-transform: uppercase;
  }
  
  .filter-btn.active {
    background-color: #3a85ff;
    color: #ffffff;
  }
  
  .portfolio-items {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .portfolio-item {
    position: relative;
    width: 540px;
    height: 500px;
    overflow: hidden;
    border-radius: 8px;
  }
  
  .portfolio-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .portfolio-item .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 255, 0.7);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .portfolio-item:hover .overlay {
    opacity: 1;
  }
  
  .project-name {
    font-size: 18px;
    font-weight: bold;
  }
  
  .tech-stack {
    padding: 10px;
    background-color: #f4f4f4;
    text-align: center;
    border-top: 1px solid #ddd;
  }
  
  .tech-stack p {
    margin: 5px 0;
    font-size: 14px;
    color: #333;
  }
  
  .tech-list {
    font-weight: bold;
    color: #555;
  }

  @media (max-width: 100px){
    .portfolio-item {
      position: relative;
      width: 240px;
      height: 200px;
      overflow: hidden;
      border-radius: 8px;
    }
  }
  
  @media (max-width: 600px){
    .portfolio-item {
      position: relative;
      width: 340px;
      height: 200px;
      overflow: hidden;
      border-radius: 8px;
    }
  }